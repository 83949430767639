
import { defineComponent, ref, reactive, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { v4 as uuidv4 } from 'uuid'
import {
  ElButton,
  ElIcon,
  ElForm,
  ElFormItem,
  FormRules,
  FormInstance,
  ElNotification,
  UploadFile,
  ElSelect,
  ElOption,
} from 'element-plus'
import { Back, Delete } from '@element-plus/icons-vue'

import { routeNames } from '@/router'
import { ICreateOrderForm, ICreatePosterForm } from '@/types'
import { usePermissions, useProducts } from '@/core/compositions'
import { apiErrorNotification } from '@/core/helpers'
import { ordersService } from '@/services'

import Posters from './components/Posters.vue'

export default defineComponent({
  name: 'CreateOrderView',

  components: {
    ElButton,
    ElIcon,
    Back,
    ElForm,
    ElFormItem,
    ElSelect,
    ElOption,

    Posters,
  },

  setup() {
    const router = useRouter()

    const { groupedPermissionsWithLabels } = usePermissions()
    const { getProductsList, productsList } = useProducts()

    const loading = ref(false)

    const formRef = ref<FormInstance>()
    const formValues = reactive<ICreateOrderForm>({
      clientEmail: null,
      clientFirstName: null,
      clientLastName: null,
      // shopifyId: 'testshopifyid2844'
      productId: null,
      notes: null,
      posters: [
        {
          imageId: null,
          videoId: null,
          uuid: uuidv4(),
        },
      ],
    })

    const elUploadRef = ref()
    const formUploadFile = ref<UploadFile | null>(null)
    const formUploadFileSrc = ref(null)

    const isClientEmailValid = ref(false)

    const selectedProduct = computed(() => {
      return productsList.value.find((i) => i.id === formValues.productId)
    })

    const formValidationRules = computed<FormRules>(() => {
      return {
        productId: [
          {
            required: true,
            message: 'Product is required!',
            trigger: 'change',
          },
        ],
        posters: [
          {
            required: true,
            message: 'Posters is required!',
            trigger: 'change',
          },
          {
            type: 'array',
            min: selectedProduct.value?.minPicturesCount || 1,
            max: selectedProduct.value?.maxPicturesCount || 1,
            message: `Posters count should be ${
              selectedProduct.value?.minPicturesCount || 1
            } to ${selectedProduct.value?.maxPicturesCount || 1}`,
            trigger: 'change',
          },
          {
            validator: (
              rule: any,
              value: ICreatePosterForm[],
              callback: any,
            ) => {
              const posterFilesUploaded = []

              if (
                selectedProduct.value?.imageRequiredInPoster &&
                selectedProduct.value?.videoRequiredInPoster
              ) {
                const isImageVideoUploaded = value.every(
                  (p) => p.imageId && p.videoId,
                )
                posterFilesUploaded.push(isImageVideoUploaded)
              }

              if (selectedProduct.value?.imageRequiredInPoster) {
                const isImageUploaded = value.every((p) => p.imageId)
                posterFilesUploaded.push(isImageUploaded)
              }

              if (selectedProduct.value?.videoRequiredInPoster) {
                const isVideoUploaded = value.every((p) => p.videoId)
                posterFilesUploaded.push(isVideoUploaded)
              }

              const isPostersValid = posterFilesUploaded.every(
                (i) => i === true,
              )
              if (!isPostersValid) {
                callback(
                  new Error(
                    'Please, upload photo and/or video to each poster!',
                  ),
                )
              } else {
                callback()
              }
            },
            message: 'Please, upload photo and/or video to each poster!',
            trigger: 'change',
          },
        ],
      }
    })

    const canAddPosters = computed(() => {
      return (
        selectedProduct.value &&
        (formValues.posters?.length as number) <
          selectedProduct.value?.maxPicturesCount
      )
    })

    onMounted(() => {
      getData()
    })

    const getData = async () => {
      loading.value = true

      try {
        await getProductsList()
      } catch (error) {
        console.error(error)
        apiErrorNotification(error)
      } finally {
        loading.value = false
      }
    }

    const onSubmit = async () => {
      const isFormValid = await formRef.value?.validate()
      if (!isFormValid) {
        return false
      }

      loading.value = true

      try {
        await ordersService.create(formValues)

        ElNotification({
          message: 'Order successfully created!',
          type: 'success',
        })

        toOrdersPage()
      } catch (error) {
        console.error(error)
        apiErrorNotification(error)
      } finally {
        loading.value = false
      }
    }

    const toOrdersPage = () => {
      router.push({ name: routeNames.orders })
    }

    const onClientEmailBlur = async () => {
      isClientEmailValid.value = false

      const isFieldValid = await formRef.value?.validateField('clientEmail')
      if (isFieldValid) {
        isClientEmailValid.value = true
      }
    }

    const onAddPoster = () => {
      formValues.posters?.push({
        imageId: null,
        videoId: null,
        uuid: uuidv4(),
      })
    }

    const onRemovePoster = (poster: ICreatePosterForm) => {
      const index = formValues.posters?.findIndex(
        (p) => p.uuid === poster.uuid,
      ) as number
      formValues.posters?.splice(index, 1)
    }

    const onUpdatePosterFileId = (posterData: {
      uuid: string
      fileId: number
      type: 'image' | 'video'
    }) => {
      const poster = formValues.posters?.find(
        (p) => p.uuid === posterData.uuid,
      ) as ICreatePosterForm

      const fileKey = posterData.type === 'image' ? 'imageId' : 'videoId'
      poster[fileKey] = posterData.fileId
    }

    return {
      loading,
      formValues,
      formValidationRules,
      formRef,
      groupedPermissionsWithLabels,
      onSubmit,
      toOrdersPage,
      formUploadFile,
      formUploadFileSrc,
      Delete,
      elUploadRef,
      productsList,
      canAddPosters,
      onAddPoster,
      onRemovePoster,
      onUpdatePosterFileId,
      onClientEmailBlur,
      isClientEmailValid,
      selectedProduct,
    }
  },
})
